import Broadcasts from '../../../app/Broadcasts'
import T3ClassDecorator from '../t3-class-decorator'
import SidebarFilters from '../product-overview/sidebar-filters'
import FilterBar from '../product-overview/filter-bar'
import UrlSearchParamsHandler from '@helpers/url-search-params-handler'
import ButtonSwitch from '@partials/gui/button-switch'
import {setElementVisibility, toggleElementClass} from '@utils/html-element-utils'

export default class BaseBikeOverview extends T3ClassDecorator {

    static moduleName = 'base-bike-overview'

    static messages = [
        Broadcasts.OPEN_SIDEBAR_FILTERS_REQUESTED,
        Broadcasts.CLOSE_SIDEBAR_FILTERS_REQUESTED,
        Broadcasts.Navigation.ON_BACKDROP_CLICKED,
    ]

    sanitizeGrid = false

    constructor(context) {
        super(context)

        this.model = {}
        this.sidebarFilters = null
        this.buttonSwitch = null

        this.photoItemsContainer = null
        this.listItemsContainer = null

        this.groupItems = null
        this.teaserItems = null

        this.urlSearchParamsHandler = new UrlSearchParamsHandler()
    }

    onmessage(name) {
        if (this.sidebarFilters === null) {
            return
        }

        if (name === Broadcasts.OPEN_SIDEBAR_FILTERS_REQUESTED) {
            this.sidebarFilters.openRequested()
        } else if (name === Broadcasts.CLOSE_SIDEBAR_FILTERS_REQUESTED) {
            this.sidebarFilters.closeRequested()
        } else if (name === Broadcasts.Navigation.ON_BACKDROP_CLICKED) {
            this.sidebarFilters.onBackdropClicked()
        }
    }

    init() {
        this.initModel()
        this.getElements()
        this.initFilterBar()
        this.initButtonSwitch()
        this.initSidebarFilters()
        this.applyModelToInstances()
        this.applyModelToResultsView()
        this.doInitialFilteringOrGridFix()
    }

    initModel() {
        this.model = {
            showType: 'photo',
            features: [],
            ...this.urlSearchParamsHandler.getParamsFromUrl(),
        }
    }

    getElements() {}

    initFilterBar() {
        const elementFilterBar = this.module.querySelector('.filter-bar')
        if (elementFilterBar) {
            this.filterBar = new FilterBar(elementFilterBar)
        }
    }

    initButtonSwitch() {
        const buttonSwitchElement = this.module.querySelector('.button-switch')
        const config = {
            name: 'showType',
            optionalValueMap: {unchecked: 'photo', checked: 'list'},
        }

        this.buttonSwitch = new ButtonSwitch(buttonSwitchElement, config, this.onButtonSwitchChanged.bind(this))
    }

    initSidebarFilters() {
        const buttonShowFilters = this.module.querySelector('.filter-bar__button-open-filters')
        const elementSidebarFilters = this.module.querySelector('.sidebar-filters')

        if (!elementSidebarFilters || !buttonShowFilters) return

        buttonShowFilters.addEventListener('click', () => {
            const fixedContentController = this.context.getService('fixed-content-controller')
            fixedContentController.openSidebarFilters()
        })

        this.sidebarFilters = new SidebarFilters(
            elementSidebarFilters,
            this.config.filtersConfig,
            this.context,
            this.onInputModelChanged.bind(this),
            this.onPrefetchRequested.bind(this)
        )
    }

    applyModelToInstances() {
        this.sidebarFilters?.setAndApplyModel(this.model)
        this.buttonSwitch.setAndApplyModel(this.model)
    }

    onInputModelChanged(filterModel) {
        this.model = {...this.model, features: filterModel.features }
        this.filterGroupItems(this.model, true)
        this.setUrlSearchParams()
        this.trackInputModelChanged(this.model)
        this.filterTeaserItems(this.teaserItems, this.sidebarFilters?.isFilterActive())
    }

    trackInputModelChanged() {}

    onButtonSwitchChanged(model) {
        this.model = {...this.model, ...model}

        if (this.sidebarFilters) {
            const counts = this.filterGroupItems(this.model, true)
            const actualCount = this.getCountHitsByActualView(counts)

            this.sidebarFilters.setAndUpdateTotalCount(actualCount)
        }

        this.applyModelToResultsView()
        this.setUrlSearchParams()
    }

    applyModelToResultsView() {
        const {showType} = this.model
        const showResultsPhoto = showType === 'photo'
        const showResultsList = showType === 'list'
        setElementVisibility(this.photoItemsContainer, showResultsPhoto)
        setElementVisibility(this.listItemsContainer, showResultsList)
    }

    setUrlSearchParams() {
        this.urlSearchParamsHandler.setParams(this.model)
    }

    doInitialFilteringOrGridFix() {
        const shouldFilter = !!this.model.features && this.model.features.length > 0

        if (shouldFilter === true) {
            this.doInitialFiltering()
        } else {
            this.sanitizeGridAllSeriesItems()
        }
    }

    doInitialFiltering() {
        if (!this.sidebarFilters) return

        this.filterTeaserItems(this.teaserItems, this.sidebarFilters?.isFilterActive())

        const counts = this.filterGroupItems(this.model, true)
        const actualCount = this.getCountHitsByActualView(counts)

        this.sidebarFilters.setAndUpdateTotalCount(actualCount)
    }

    onPrefetchRequested(filterModel) {
        if (!this.sidebarFilters) return

        const tempModel = {...this.model, ...filterModel}
        const counts = this.filterGroupItems(tempModel)
        const actualCount = this.getCountHitsByActualView(counts)

        this.sidebarFilters.onPrefilterSuccess(actualCount)
    }

    getCountHitsByActualView({countGroupsMatched, countTotalChildrenMatched}) {
        const {showType} = this.model

        if (showType === 'photo') {
            return countGroupsMatched
        }

        if (showType === 'list') {
            return countTotalChildrenMatched
        }
    }

    sanitizeGridAllSeriesItems() {
        if (!this.sanitizeGrid) {
            return
        }

        this.groupItems.forEach((groupItem, index) => {
            const type = groupItem.getAttribute('data-type')
            if (type === 'photo') {
                this.setGridClassesByIndex(groupItem, index)
            }
        })
    }

    filterGroupItems(model, applyFiltering) {
        const selectedFeatures = model.features
        let countGroupsMatched = 0
        let countTotalChildrenMatched = 0

        this.groupItems.forEach((groupItem) => {
            const type = groupItem.getAttribute('data-type')

            if (type !== this.model.showType) {
                return
            }

            const children = groupItem.querySelectorAll('[data-filter-item="child"]')
            const applyFilteringToChildren = applyFiltering && type === 'list'
            const countChildrenMatched = this.filterChildren(children, selectedFeatures, applyFilteringToChildren)
            const groupMatches = countChildrenMatched > 0

            if (groupMatches === true) {
                countGroupsMatched += 1
                countTotalChildrenMatched += countChildrenMatched
            }

            if (applyFiltering === true) {
                this.setElementVisibility(groupItem, groupMatches)
            }

            if (
                this.sanitizeGrid
                && groupMatches === true
                && applyFiltering === true
                && type === 'photo'
            ) {
                const indexGroupsMatched = countGroupsMatched - 1
                this.setGridClassesByIndex(groupItem, indexGroupsMatched)
            }
        })

        return {
            countGroupsMatched,
            countTotalChildrenMatched,
        }
    }

    filterChildren(children, selectedFeatures, applyFilteringToChildren) {
        let countChildrenMatched = 0

        children.forEach((child) => {
            const featuresString = child.getAttribute('data-features')
            const childFeatures = featuresString.split(',')

            const childMatches = selectedFeatures.every((feature) => {
                return childFeatures.includes(feature)
            })

            if (childMatches) {
                countChildrenMatched += 1
            }

            if (applyFilteringToChildren) {
                this.setElementVisibility(child, childMatches)
            }
        })

        return countChildrenMatched
    }

    /**
     * Changes the visibility of teaser items based on the applyFiltering parameter.
     * @param {Array} teaserItems - An array of teaser items to be filtered.
     * @param {boolean | undefined} applyFiltering - A boolean indicating whether to apply filtering (true) or not (false).
     * @returns {void} This function does not return anything.
     */
    filterTeaserItems(teaserItems, applyFiltering) {
        teaserItems?.forEach((teaserItem) => {
            setElementVisibility(teaserItem, !applyFiltering)
        })
    }

    setGridClassesByIndex(groupItem, index) {
        const hasOffset = index % 2 !== 0
        toggleElementClass(groupItem, 'l-left-2', hasOffset)
    }

    setElementVisibility(item, visible) {
        item.style.display = visible ? 'block' : 'none'
    }
}
