import { toggleElementClass } from '@utils/html-element-utils'
import { inMqSmall } from '@utils/browser-utils'
import { debounce } from 'underscore'
import BaseComponent from './base-component'

const templateDropdown = `
    <div class="dropdown<% if (typeof reducedMobileView !== 'undefined' && reducedMobileView && typeof reducedMobileIcon !== 'undefined' && reducedMobileIcon) { %> dropdown--reduced-mobile-view<% }; %>">
        <select id="<%= name %>" name="<%= name %>">
            <% _.each( items, function( item, index ) { %>
            <option value="<%= item.name %>"><%= item.label %></option>
            <% }); %>
        </select>

        <div class="dropdown__label">
            <span><%= label %></span>
            <% if (typeof reducedMobileView !== 'undefined' && reducedMobileView && typeof reducedMobileIcon !== 'undefined' && reducedMobileIcon) { %>
            <div class="dropdown__icon-mobile">
                <%= reducedMobileIcon %>
            </div>
            <% }; %>
        </div>

        <svg class="dropdown__arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.66 20.56" width="31.66px" height="20.56px">
            <path vector-effect="non-scaling-stroke" d="M30.83.56,19.51,17.39a4.56,4.56,0,0,1-3.64,2.17,4.56,4.56,0,0,1-3.65-2.17L.83.64" fill="none" stroke-miterlimit="10" stroke-width="2" />
        </svg>

        <svg class="dropdown__arrow dropdown__arrow__back" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.66 20.56" width="31.66px" height="20.56px" transform="rotate(180)">
            <path vector-effect="non-scaling-stroke" d="M30.83.56,19.51,17.39a4.56,4.56,0,0,1-3.64,2.17,4.56,4.56,0,0,1-3.65-2.17L.83.64" fill="none" stroke-miterlimit="10" stroke-width="2" />
        </svg>

        <div class="dropdown__pop-up">
            <div class="dropdown__mask">
                <div class="dropdown__items">
                    <% _.each( items, function( item, index ) { %>
                    <div class="dropdown__item" data-name="<%= item.name %>"><%= item.label %></div>
                    <% }); %>
                </div>
            </div>
        </div>
    </div>
`

export default class Dropdown extends BaseComponent {

    static baseConfig = {
        dispatchSelectChange: false,
        outputRawModelValue: false,
        template: templateDropdown,
        showSelection: false,
    }

    constructor(config, callback) {
        super()

        this.config = { ...this.constructor.baseConfig, ...config }
        this.callback = callback
        this.model = null
        this.options = []
        this.select = null
        this.label = null
        this.dropdown = null
        this.isExpanded = false
        this.references = {
            onKeyPressed: this.onKeyPressed.bind(this),
            onDocumentClicked: this.onDocumentClicked.bind(this),
        }

        this.debounceUpdateLabel = debounce(this.tryUpdateLabelFromModel.bind(this), 150)

        this.init()
    }

    get templateString() {
        return templateDropdown
    }

    onRendered() {
        this.parseItems()
        this.findElements()
        this.addEventListeners()
    }

    findElements() {
        this.select = this.html.querySelector('select')
        this.label = this.html.querySelector('.dropdown__label')
        this.dropdown = this.html.querySelector('.dropdown')
        this.backLabel = this.html.querySelector('.dropdown__arrow__back')
    }

    parseItems() {
        this.config.items.forEach((item) => {
            const { name } = item
            const selector = `.dropdown__item[data-name="${name}"]`
            const option = this.html.querySelector(selector)
            this.options.push(option)

            this.addEventListenerOption(option)
        })
    }

    addEventListenerOption(option) {
        option.addEventListener('click', this.onOptionChanged.bind(this))
    }

    addEventListeners() {
        this.label.addEventListener('click', this.onLabelClicked.bind(this))
        window.addEventListener('resize', this.debounceUpdateLabel.bind(this))
    }

    onOptionChanged(event) {
        const element = event.target
        const value = element.getAttribute('data-name')

        this.closeDropdown()
        this.setModelValue(value)
        this.applyModelToItems()
        this.applyModelToNativeSelect()
        this.tryCallback()
        this.tryUpdateLabelFromModel()

        if (this.config.dispatchSelectChange) {
            this.dispatchSelectChange()
        }
    }

    dispatchSelectChange() {
        this.select.dispatchEvent(new CustomEvent('change'))
    }

    openDropdown() {
        this.isExpanded = true
        this.applyExpanded()

        // add delayed to avoid catching ongoing click-event from label
        window.requestAnimationFrame(() => {
            document.addEventListener('keydown', this.references.onKeyPressed)
            document.addEventListener('click', this.references.onDocumentClicked)
        })
    }

    closeDropdown() {
        this.isExpanded = false
        this.applyExpanded()

        document.removeEventListener('keydown', this.references.onKeyPressed)
        document.removeEventListener('click', this.references.onDocumentClicked)
    }

    onDocumentClicked(event) {
        const wasClickOutside = this.dropdown.contains(event.target) === false

        if (wasClickOutside) {
            this.closeDropdown()
        }
    }

    onLabelClicked() {
        if (this.isExpanded === false) {
            this.openDropdown()
        } else {
            this.closeDropdown()
        }
    }

    onKeyPressed(event) {
        const { key } = event

        if (key && (key === 'Escape' || key === 'Esc')) {
            this.closeDropdown()
        }
    }

    applyExpanded() {
        toggleElementClass(this.dropdown, 'dropdown--expanded', this.isExpanded)
    }

    setModelValue(value) {
        this.model = value
    }

    tryUpdateLabelFromModel() {
        const selectedItem = this.config.items.find(({name}) => name === this.model)
        const hasReducedMobileIcon = this.config.reducedMobileView && this.config.reducedMobileIcon

        if (this.config.showSelection === true) {
            this.label.innerHTML = selectedItem ? selectedItem.label : this.config.label
        }

        if (hasReducedMobileIcon && inMqSmall()) {
            this.label.innerHTML = `
                <span>${selectedItem.label}</span>
                <div class="dropdown__icon-mobile">
                    ${this.config.reducedMobileIcon}
                </div>
            `
        }
    }

    applyModelToItems() {
        this.options.forEach(option => {
            const name = option.getAttribute('data-name')
            const isSelected = name === this.model

            toggleElementClass(option, 'dropdown__item--selected', isSelected)
        })
    }

    applyModelToNativeSelect() {
        this.select.value = this.model
    }

    getMappedOutput() {
        return {
            [ this.config.name ]: this.model
        }
    }

    tryCallback() {
        if (this.callback) {
            const output = this.config.outputRawModelValue ? this.model : this.getMappedOutput()
            this.callback(output)
        }
    }

    setAndApplyModel(modelAllFilters) {
        const value = modelAllFilters[this.config.name]

        if (value) {
            const sanitizedValue = this.sanitizeValue(value)
            this.setModelValue(sanitizedValue)
            this.applyModelToItems()
            this.applyModelToNativeSelect()
            this.tryUpdateLabelFromModel()
        }
    }

    sanitizeValue(dirtyValue) {
        return Array.isArray(dirtyValue) ? dirtyValue[0] : dirtyValue
    }
}
