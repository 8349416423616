import iconArrowDown from '@icon_templates/icon-arrow-down'
import { toggleElementClass } from '@utils/html-element-utils'

const wrapperTemplateString = (slotContent) => {
    return `<div class="wrapper-select">
                        <div class="wrapper-select__header">
                            <div class="wrapper-select__header-inner">
                                <span class="wrapper-select__label"><%= label %></span>
                                <button class="wrapper-select__button-expand">${iconArrowDown}</button>
                            </div>
                            <div class="wrapper-select__preview"></div>
                        </div>
                        <div class="wrapper-select__content">${slotContent}</div>
                    </div>`
}

const extendSelectWithWrapper = (selectClass) => {
    return class WrapperSelect extends selectClass {

        constructor(...args) {
            super(...args)

            this.typePreviewMethodMap = {
                'select-options-single': this.getPreviewForSelectOptionsSingle.bind(this),
                'select-options-multi': this.getPreviewForSelectOptions.bind(this),
                'select-colors': this.getPreviewForSelectOptions.bind(this),
                'select-range': this.getPreviewForSelectRange.bind(this),
                'select-range-single': this.getPreviewForSelectRangeSingle.bind(this),
            }

            this.callbackOpenWrapper = args[2]
            this.wrapperElement = null
            this.previewElement = null
            this.isExpanded = false
        }

        get templateString() {
            const baseTemplateString = super.templateString
            return wrapperTemplateString(baseTemplateString)
        }

        init() {
            super.init()
            this.getAdditionalElements()
            this.addAdditionalEventListeners()
        }

        setModelValue(...args) {
            super.setModelValue(...args)
            this.updatePreview()
        }

        updatePreview() {
            const getPreviewMethod = this.typePreviewMethodMap[this.constructor.type]

            if (getPreviewMethod && this.previewElement) {
                const previewString = getPreviewMethod()
                this.previewElement.innerHTML = previewString
            }
        }

        getPreviewForSelectOptions() {
            if (this.model.length < 1) {
                return ''
            }

            let string = ''

            this.model.forEach((value) => {
                const matchedItem = this.config.items.find((item) => item.name === value)

                if (matchedItem) {
                    const separatorString = (string.length > 0) ? ' | ' : ''
                    string += `${separatorString}${matchedItem.label}`
                }
            })

            return string
        }

        getPreviewForSelectOptionsSingle() {
            return this.model
        }

        getPreviewForSelectRange() {
            if (this.isPristine === false) {
                const { valueMin, valueMax } = this.model
                return `${valueMin} - ${valueMax}${this.config.labelMin}`
            } else {
                return ''
            }
        }

        getPreviewForSelectRangeSingle() {
            if (this.isPristine === false) {
                return `${this.model}${this.config.labelCenter}`
            } else {
                return ''
            }
        }

        getAdditionalElements() {
            this.wrapperElement = this.html.querySelector('.wrapper-select')
            this.previewElement = this.html.querySelector('.wrapper-select__preview')
        }

        addAdditionalEventListeners() {
            const header = this.html.querySelector('.wrapper-select__header')
            header.addEventListener('click', this.onExpandClicked.bind(this))
        }

        onExpandClicked() {
            this.isExpanded = !this.isExpanded
            this.updateExpandedState()
            this.tryDispatchWasOpened()
        }

        tryDispatchWasOpened() {
            if (this.isExpanded === true && typeof this.callbackOpenWrapper === 'function') {
                this.callbackOpenWrapper(this.name)
            }
        }

        setAndApplyExpanded(isExpanded) {
            this.isExpanded = isExpanded
            this.updateExpandedState()
        }

        updateExpandedState() {
            toggleElementClass(this.wrapperElement, 'wrapper-select--expanded', this.isExpanded)
        }

        // get name(){
        //     return this.config.name
        // }
    }
}

export default extendSelectWithWrapper

