import iconLowFrame from '@icon_templates/icon-low-frame'
import iconMixteFrame from '@icon_templates/icon-mixte-frame'
import iconHighFrame from '@icon_templates/icon-high-frame'
import iconFrontLoader from '@icon_templates/icon-front-loader'
import iconLongtail from '@icon_templates/icon-longtail'

const frameIcon = (slug) => {
    if (slug === 'low-frame') {
        return iconLowFrame
    }

    if (slug === 'mixte-frame') {
        return iconMixteFrame
    }

    if (slug === 'high-frame') {
        return iconHighFrame
    }

    if (slug === 'frontloader') {
        return iconFrontLoader
    }

    if (slug === 'longtail') {
        return iconLongtail
    }

    return ''
}

export default frameIcon
