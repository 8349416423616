import {transformToKebabCase} from '@utils/string'

export default class BaseFilterParser {
    constructor() {
        this.typeMethodMap = {
            'select-colors': this.getConfigColors.bind(this),
            'select-options-single': this.getConfigSelectSingle.bind(this),
            'select-options-multi': this.getConfigSelectMulti.bind(this),
            'select-range': this.getConfigSelectRange.bind(this),
            'select-range-single': this.getConfigSelectRangeSingle.bind(this),
        }

        this.iterableFiltersList = [
            'attributes',
            'categories',
            'metadata',
        ]

        this.rangeFiltersList = []
        this.filterOptionsList = []
        this.filterOrderBySlug = {}
        this.slugTypeMap = {}
        this.parsedFilters = []
        this.filterExclusionMap = {}
    }

    // getConfigColors(item){
    //     const { name, label, values } = item
    //
    //     const items = values.map(value => {
    //         const { name: nameItem, label: labelItem, disabled } = value
    //         return {
    //             name: nameItem,
    //             id: transformToKebabCase(nameItem),
    //             label: labelItem,
    //             color: this.colorNameHexCodeMap[nameItem],
    //             disabled,
    //         }
    //     })
    //
    //     const sortedItems = this.getSortedColorItems(items)
    //
    //     return {
    //         name,
    //         label,
    //         type: 'select-colors',
    //         items: sortedItems,
    //     }
    // }

    getConfigSelectBase(item, type) {
        const { name, label, values } = item

        const items = values.map(value => {
            const { name: nameItem, label: labelItem, disabled } = value
            return {
                name: nameItem,
                id: transformToKebabCase(nameItem),
                label: labelItem || nameItem,
                disabled,
            }
        })

        return {
            name,
            label: label || name,
            items,
            type,
        }
    }

    getConfigColors(item) {
        return this.getConfigSelectBase(item, 'select-colors')
    }

    getConfigSelectSingle(item) {
        return this.getConfigSelectBase(item, 'select-options-single')
    }

    getConfigSelectMulti(item) {
        return this.getConfigSelectBase(item, 'select-options-multi')
    }

    getConfigSelectRange(item) {
        const { name, label, baseMax, baseMin, filterMax, filterMin } = item
        return {
            name,
            label,
            type: 'select-range',
            nameMin: `${name}-min`,
            nameMax: `${name}-max`,
            filterMin,
            filterMax,
            baseMin,
            baseMax,
            labelMin: ' €',
            labelMax: ' €',
        }
    }

    getConfigSelectRangeSingle(item) {
        const { name, label, baseMax, baseMin, filterMax, filterMin } = item
        return {
            name,
            label,
            type: 'select-range-single',
            filterMin, // todo: reset
            filterMax,
            value: baseMin + (baseMax - baseMin) / 2,
            min: baseMin,
            max: baseMax,
            labelCenter: ' cm',
        }
    }

    filterFactory(item) {
        const { name } = item
        const type = this.slugTypeMap[name]
        const method = this.typeMethodMap[type]

        if (method) {
            return method(item)
        }
    }

    getParsedFilterItemIterable(item) {
        return this.filterFactory(item)
    }

    getParsedFilterItemOptions(key, value) {
        const sanitizedItem = { values: value, name: key }
        return this.filterFactory(sanitizedItem)
    }

    getParsedFilterItemRange(key, value) {
        const sanitizedItem = { ...value, name: key }
        return this.filterFactory(sanitizedItem)
    }

    handleFilterConfigCreated(name, filterConfig, parent = null) {
        this.addFilterAtNamedIndex(name, filterConfig)
    }

    addFilterAtNamedIndex(name, filterConfig) {
        const index = this.filterOrderBySlug[name]
        this.parsedFilters[index] = filterConfig
    }

    parseFilters(filters) {
        this.parsedFilters = []

        Object.entries(filters).forEach(([key, value]) => {
            const isFilterIterable = this.iterableFiltersList.includes(key)
            const isFilterRange = this.rangeFiltersList.includes(key)
            const isFilterOptions = this.filterOptionsList.includes(key)

            if (isFilterOptions) {
                const filterConfig = this.getParsedFilterItemOptions(key, value)
                if (filterConfig) {
                    this.handleFilterConfigCreated(key, filterConfig, null)
                }
                return
            }

            if (isFilterIterable) {
                value.forEach((item) => {
                    const filterConfig = this.getParsedFilterItemIterable(item)
                    if (filterConfig) {
                        this.handleFilterConfigCreated(filterConfig.name, filterConfig, key)
                    }
                })
                return
            }

            if (isFilterRange) {
                const filterConfig = this.getParsedFilterItemRange(key, value)
                if (filterConfig) {
                    this.handleFilterConfigCreated(key, filterConfig, null)
                }
                return
            }
        })

        return this.parsedFilters
    }

    excludeFilters(parsedFilters) {
        const filtersWoExcluded = parsedFilters.filters.filter((filter) => {
            const filterExclusionFunction = this.filterExclusionMap[filter.name]

            if (typeof filterExclusionFunction === 'function') {
                return !filterExclusionFunction(filter)
            }

            return true
        })

        return {
            filters: filtersWoExcluded,
            totalCount: parsedFilters.totalCount
        }
    }
}
