import T3ClassDecorator from '../t3-class-decorator'
import { fetchQuestions, fetchResults } from '~/app-modules/api/rest/bike-advisor-api'
import BikeAdvisorQuestion from '@modules/bike-advisor/partials/bike-advisor-question'
import BikeAdvisorChoices from '@modules/bike-advisor/partials/bike-advisor-choices'
import UrlSearchParamsHandler from '~/app-modules/helpers/url-search-params-handler'
import {getNodeRecursively, timeoutPromise} from '@modules/bike-advisor/bike-advisor-helper'
import BikeAdvisorProgress from '@modules/bike-advisor/partials/bike-advisor-progress'
import BikeAdvisorResults from '@modules/bike-advisor/partials/bike-advisor-results'
import BikeAdvisorSummary from '@modules/bike-advisor/partials/bike-advisor-summary'
import BikeAdvisorHeader from '@modules/bike-advisor/partials/bike-advisor-header'
import BikeAdvisorTool from '@modules/bike-advisor/partials/bike-advisor-tool'
import { scrollElementIntoView } from '~/app-modules/utils/animation-utils'
import {tryApplyInvertedGui} from '@utils/apply-inverted-gui'
import {
    trackBackClicked, trackBackRestartClicked,
    trackChoiceClicked,
    trackCompleted, trackPlaceholderClicked,
    trackRestartClicked, trackResultsViewed, trackSelectionClicked,
    trackStartClicked, trackSummaryViewed
} from './bike-advisor-tracking'

const LOADING = 'loading'
const START = 'start'
const FADE = 'fade'
const ASK = 'ask'
const END = 'end'

export default class BikeAdvisor extends T3ClassDecorator {

    static moduleName = 'bike-advisor'

    constructor(context) {
        super(context)

        this.questions = []
        this.questionModule = null
        this.choicesModule = null
        this.progressModule = null
        this.resultsModule = null
        this.summaryModule = null
        this.urlSearchParamsHandler = new UrlSearchParamsHandler()
    }

    async init() {
        this.setState(LOADING)

        // prevent scrolling to old window position
        history.scrollRestoration = 'manual'

        tryApplyInvertedGui(this.module)
        this.removeBreadcrumb()

        const selectedSlugs = this.slugsFromUrlParams
        const isLateralEntry = selectedSlugs.length > 0

        if (isLateralEntry) {
            this.scrollToolIntoView(true)
        }

        const response = await fetchQuestions()
        this.questions = response.data

        this.initGui()
        this.initSubModules()

        //         const selectedSlugs = this.slugsFromUrlParams
        const actualSlug = selectedSlugs[selectedSlugs.length - 1]
        const actualQuestion = this.getQuestionByChoiceSlug(actualSlug)
        const actualIndex = actualQuestion ? this.getIndexFromQuestion(actualQuestion) : -1
        const { node: nextQuestion, index: nextIndex } = getNodeRecursively(this.questions, selectedSlugs, actualIndex)

        if (isLateralEntry) {
            await this.fillQuestionModule(this.questionModule, selectedSlugs)
            await timeoutPromise(350)
        }

        this.updateProgressBar(nextIndex)

        if (nextQuestion) {
            await Promise.all([
                this.questionModule.addSentenceWithPlaceholder(nextQuestion, selectedSlugs),
                this.choicesModule.addQuestion(nextQuestion, selectedSlugs)
            ])

            this.toolModule.sanitizeHeight(isLateralEntry)

            if (isLateralEntry) {
                this.startTimeoutPulse()
            }

            this.setState(nextIndex < 1 ? START : ASK)
        } else {
            this.toolModule.sanitizeHeight()
            this.showResults(selectedSlugs, true)
        }
    }

    removeBreadcrumb() {
        // sorry for misspelling
        const breadcrumbElement = document.querySelector('nav.breadcrump')

        if (breadcrumbElement) {
            breadcrumbElement.remove()
        }
    }

    fillQuestionModule(module, selectedSlugs) {
        return Promise.all(
            selectedSlugs.map((slug) => {
                const question = this.getQuestionByChoiceSlug(slug)
                const choice = question.choices.find((choice) => choice.slug === slug)

                return module.addSentenceWithChoiceInstant(question, choice, selectedSlugs)
            })
        )
    }

    getQuestionByChoiceSlug(slug) {
        return this.questions.find(({ choices }) => {
            return choices.find((choice) => choice.slug === slug)
        })
    }

    initGui() {
        const startButton = this.module.querySelector('.bike-advisor__start-button')
        const backButtons = this.module.querySelectorAll('.bike-advisor__tool-button-back, .bike-advisor__button-back')
        const restartButtons = this.module.querySelectorAll('.bike-advisor__tool-button-restart, .bike-advisor__button-restart')

        backButtons.forEach((button) => button.addEventListener('click', this.gotoPreviousQuestion.bind(this)))
        restartButtons.forEach((button) => button.addEventListener('click', this.gotoFirstQuestion.bind(this)))
        startButton.addEventListener('click', this.onStartButtonClicked.bind(this))
    }

    async onStartButtonClicked() {
        trackStartClicked()

        this.setState(FADE)
        this.startTimeoutPulse(1500)
        await this.scrollToolIntoView()

        this.setState(
            this.slugsFromUrlParams.length > 0 ? ASK : START
        )
    }

    initSubModules() {
        this.questionModule = new BikeAdvisorQuestion(
            this.module.querySelector('.bike-advisor__question'),
            this.onPlaceholderClicked.bind(this),
            this.onFilledPlaceholderClicked.bind(this)
        )

        this.choicesModule = new BikeAdvisorChoices(
            this.module.querySelector('.bike-advisor__choices'),
            this.onChoiceClicked.bind(this),
            this.onChoiceMouseOver.bind(this),
            this.onChoiceMouseOut.bind(this)
        )

        this.progressModule = new BikeAdvisorProgress(
            this.module,
            this.questions
        )

        this.resultsModule = new BikeAdvisorResults(
            this.module.querySelector('.bike-advisor__results'),
            this.config.translations ?? {}
        )

        this.summaryModule = new BikeAdvisorSummary(this.module)

        this.headlineModule = new BikeAdvisorHeader(this.module.querySelector('.bike-advisor__header'))

        this.toolModule = new BikeAdvisorTool(
            this.module.querySelector('.bike-advisor__tool')
        )
    }

    startTimeoutPulse(timeout = 4000) {
        clearTimeout(this.timeoutPulse)
        this.timeoutPulse = setTimeout(() => {
            this.choicesModule.playPulseAnimation()
        }, timeout)
    }

    stopTimeoutPulse() {
        clearTimeout(this.timeoutPulse)
    }

    onPlaceholderClicked() {
        trackPlaceholderClicked()
        this.choicesModule.playPulseAnimation()
    }

    async onFilledPlaceholderClicked(question, selectedChoice) {
        this.setState(FADE)
        this.stopTimeoutPulse()

        trackSelectionClicked()

        const selectedSlugs = this.slugsFromUrlParams
        const indexSelectedChoice = selectedSlugs.indexOf(selectedChoice.slug)
        const newSlugs = selectedSlugs.slice(0, indexSelectedChoice)
        const countRemove = selectedSlugs.length - newSlugs.length
        const actualIndex = this.getIndexFromQuestion(question)

        this.saveSlugsAsUrlParams(newSlugs)
        this.updateProgressBar(actualIndex)
        this.setFadeTarget(actualIndex < 1 ? START : ASK)

        this.questionModule.lockHeight()
        this.choicesModule.lockHeight()

        await Promise.all([
            this.scrollToolIntoView(),
            this.questionModule.removeLastSentences(countRemove),
            this.choicesModule.addQuestion(question, newSlugs),
        ])

        this.toolModule.sanitizeHeight()

        this.startTimeoutPulse()
        this.setState(actualIndex < 1 ? START : ASK)
    }

    onChoiceMouseOver(question, choice) {
        this.questionModule.previewChoiceWithinActualSentence(choice)
        this.stopTimeoutPulse()
    }

    onChoiceMouseOut() {
        this.questionModule.removeChoiceFromActualSentence()
    }

    async onChoiceClicked(question, choice) {
        this.setState(FADE)
        this.setFadeTarget(ASK)
        this.stopTimeoutPulse()

        trackChoiceClicked(choice.slug)

        const selectedSlugs = this.slugsFromUrlParams
        selectedSlugs.push(choice.slug)

        const actualIndex = this.getIndexFromQuestion(question)
        const { node: nextQuestion, index: nextIndex } = getNodeRecursively(this.questions, selectedSlugs, actualIndex)

        this.saveSlugsAsUrlParams(selectedSlugs)

        await Promise.all([
            this.choicesModule.hideQuestion(),
            this.questionModule.setChoiceToActualSentence(choice),
        ])

        this.updateProgressBar(nextIndex)

        if (nextQuestion) {
            await Promise.all([
                this.scrollToolIntoView(),
                this.questionModule.addSentenceWithPlaceholder(nextQuestion, selectedSlugs),
                this.choicesModule.addQuestion(nextQuestion, selectedSlugs),
            ])

            this.toolModule.sanitizeHeight()

            this.startTimeoutPulse()
            this.setState(ASK)
        } else {
            trackCompleted(selectedSlugs)

            await Promise.all([
                // todo: scroll obsolete?
                this.scrollToolIntoView(),
                this.choicesModule.hideQuestion()
            ])

            this.toolModule.sanitizeHeight()
            // this.setState(END)

            this.showResults(selectedSlugs)
        }
    }

    getIndexFromQuestion(question) {
        return this.questions.findIndex((q) => q.slug === question.slug)
    }

    updateProgressBar(index) {
        this.progressModule.setProgressByIndex(index)
    }

    async gotoFirstQuestion() {
        this.setState(FADE)
        this.setFadeTarget(START)

        trackRestartClicked()

        const index = 0
        const selectedSlugs = []
        const question = this.questions[index]

        this.saveSlugsAsUrlParams(selectedSlugs)
        this.updateProgressBar(index)

        this.summaryModule.setScrollingTopLocked(false)

        await this.scrollToolIntoView()

        this.choicesModule.removeChoices()
        this.questionModule.removeAllSentencesInstant()
        this.resultsModule.removeResults()
        this.toolModule.sanitizeHeight()

        await this.summaryModule.hide()

        await Promise.all([
            this.questionModule.addSentenceWithPlaceholder(question, selectedSlugs),
            this.choicesModule.addQuestion(question, selectedSlugs),
        ])

        this.toolModule.sanitizeHeight()
        this.progressModule.setVisibility(true)

        this.startTimeoutPulse()
        this.setState(START)
    }

    async gotoPreviousQuestion() {
        if (this.state === ASK) trackBackClicked()
        if (this.state === END) trackBackRestartClicked()

        this.setState(FADE)
        this.stopTimeoutPulse()

        const previousChosenSlugs = this.slugsFromUrlParams
        const actualSlug = previousChosenSlugs[previousChosenSlugs.length - 1]
        const newSlugs = previousChosenSlugs.filter((item, index) => index < previousChosenSlugs.length - 1)

        const actualQuestion = this.getQuestionByChoiceSlug(actualSlug)
        const actualIndex = this.getIndexFromQuestion(actualQuestion)
        const { node: nextPossibleQuestion } = getNodeRecursively(this.questions, previousChosenSlugs, actualIndex)

        this.summaryModule.setScrollingTopLocked(false)
        this.saveSlugsAsUrlParams(newSlugs)
        this.updateProgressBar(actualIndex)

        this.setFadeTarget(actualIndex < 1 ? START : ASK)

        await Promise.all([
            this.scrollToolIntoView(),
            // 1. actual question is not last one: remove last sentence entirely
            // 2. actual question is last one: only remove choice (unlock) from last sentence
            nextPossibleQuestion
                ? this.questionModule.removeLastSentences(1)
                : this.questionModule.removeChoiceFromActualSentence(),
            this.choicesModule.addQuestion(actualQuestion, newSlugs),
        ])

        this.toolModule.sanitizeHeight()

        this.progressModule.setVisibility(true)
        this.summaryModule.hide()
        this.resultsModule.removeResults()

        this.startTimeoutPulse()
        this.setState(actualIndex < 1 ? START : ASK)
    }

    get csrfMiddlewareToken() {
        return this.module.querySelector('input[name="csrfmiddlewaretoken"]').value
    }

    async showResults(slugs) {
        this.summaryModule.removeOutput()
        await this.summaryModule.resetAnimations()
        await this.summaryModule.show()

        trackSummaryViewed()

        this.progressModule.setVisibility(false)

        const aggregatedResults = await Promise.all([
            fetchResults(slugs, this.csrfMiddlewareToken),
            this.fillSummaryModule(slugs),
        ])

        await timeoutPromise(300)

        this.summaryModule.showButtons()

        const { data: results = [] } = aggregatedResults[0]

        this.resultsModule.showResults(results, true)

        await timeoutPromise(
            this.getTimeoutForReadingBySlugs(slugs)
        )

        this.summaryModule.setScrollingTopLocked(true)
        this.scrollResultsIntoView()

        trackResultsViewed()

        this.context.broadcast(Broadcasts.Intercom.ENABLE_LOAD)
        this.context.broadcast(Broadcasts.Intercom.LOAD)

        this.setState(END)
    }

    getTimeoutForReadingBySlugs(slugs) {
        return slugs.length * 200
    }

    async fillSummaryModule(selectedSlugs) {
        const sentences = selectedSlugs.map((slug) => {
            const question = this.getQuestionByChoiceSlug(slug)
            const choice = question.choices.find((choice) => choice.slug === slug)

            return {
                question,
                choice,
            }
        })

        await this.summaryModule.addSentencesWithChoiceAnimated(sentences, selectedSlugs)
    }

    scrollToolIntoView(instant = false) {
        const scrollOptions = {
            duration: instant ? 0 : 0.8,
            reference: 'bottom',
            offset: -1 * window.innerHeight,
        }

        const toolElement = this.module.querySelector('.bike-advisor__tool')

        return scrollElementIntoView(toolElement, scrollOptions)
    }

    scrollResultsIntoView() {
        const scrollOptions = {
            duration: 1.5,
            reference: 'top',
            // offset: -1 * window.innerHeight,
        }

        return scrollElementIntoView(this.module.querySelector('.bike-advisor__results'), scrollOptions)
    }

    get slugsFromUrlParams() {
        return this.urlSearchParamsHandler.getDecodedParams('advisor').slugs ?? []
    }

    saveSlugsAsUrlParams(slugs) {
        this.urlSearchParamsHandler.setEncodedParams({ slugs }, 'advisor')
    }

    setState(state) {
        this.module.setAttribute('data-state', state)
    }

    get state() {
        return this.module.getAttribute('data-state')
    }

    setFadeTarget(state) {
        this.module.setAttribute('data-fade-to', state)
    }

    handleError() {}
}


