/*global TweenLite, Cubic, axios, ga4Custom, $, _ */

import { Constants, Config } from '~/app/Constants';

// vendor
const _TweenLite = TweenLite
const _Cubic = Cubic
const _axios = axios
const _$ = $
const __ = _

// app
const _config = Config
const _constants = Constants

// html injected
const _ga4Custom = ga4Custom

export {
    _TweenLite as TweenLite,
    _Cubic as Cubic,
    _axios as axios,
    _$ as $,
    __ as _,
    _config as Config,
    _constants as Constants,
    _ga4Custom as ga4Custom,
}
