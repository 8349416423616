import Broadcasts from '../../../app/Broadcasts'
import T3ClassDecorator from '../t3-class-decorator'
import SidebarFilters from './sidebar-filters'
import FilterBar from './filter-bar'
import Pagination from '@partials/gui/pagination'
import CountResults from '@partials/gui/count-results'
import Dropdown from '@partials/selects/dropdown'
import UrlSearchParamsHandler from '@helpers/url-search-params-handler'
import ProductOverviewApi from '@api/graph-ql/product-overview-api'
import ProductOverviewOutput from './product-overview-output'
import ConditionToggle, {CONDITION_TOGGLE_FILTER_NAME, CONDITION_TOGGLE_FILTER_ITEM_NEW} from './condition-toggle'

export default class ProductOverview extends T3ClassDecorator {

    static moduleName = 'product-overview'
    static messages = [
        Broadcasts.OPEN_SIDEBAR_FILTERS_REQUESTED,
        Broadcasts.CLOSE_SIDEBAR_FILTERS_REQUESTED,
        Broadcasts.Navigation.ON_BACKDROP_CLICKED,
    ]

    static baseConfigGui = {
        sort: true,
        countResults: true,
        filters: true,
        pagination: true,
    }

    constructor(context) {
        super(context)

        this.results = null
        this.model = {}
        this.filterBar = null
        this.sidebarFilters = null
        this.pagination = null
        this.countResults = null
        this.dropdownSort = null
        this.output = null
        this.filtersConfig = null

        this.configGui = { ...this.constructor.baseConfigGui, ...this.config.gui }

        const countryIso = context.getGlobalConfig().countryIso.toUpperCase()
        const baseFilter = {
            ...this.config.baseFilter,
            countries: [countryIso]
        }

        const apiConfig = {
            apiUrl: this.config.apiUrl,
            translations: this.config.translations,
            baseFilter,
        }

        this.urlSearchParamsHandler = new UrlSearchParamsHandler()
        this.productOverviewApi = new ProductOverviewApi(apiConfig)
    }

    onmessage(name) {
        if (this.sidebarFilters === null) {
            return
        }
        if (name === Broadcasts.OPEN_SIDEBAR_FILTERS_REQUESTED) {
            this.sidebarFilters.openRequested()
        } else if (name === Broadcasts.CLOSE_SIDEBAR_FILTERS_REQUESTED) {
            this.sidebarFilters.closeRequested()
        } else if (name === Broadcasts.Navigation.ON_BACKDROP_CLICKED) {
            this.sidebarFilters.onBackdropClicked()
        }
    }

    async init() {
        this.createInitialModel()
        this.initGuiInstances()
        this.applyModelToInstances()

        // finally
        if (this.configGui.filters) {
            await this.fetchFiltersConfig()
            this.prefetchFilters(this.model, this.handleInitialFilterPrefetchSuccess.bind(this))
        }

        this.fetchResults()
    }

    initGuiInstances() {
        if (this.configGui.sorting || this.configGui.countResults || this.configGui.filters) {
            this.initFilterBar()
        }

        if (this.configGui.pagination) {
            this.initPagination()
        }

        if (this.configGui.countResults) {
            this.initCountResults()
        }

        if (this.configGui.sorting) {
            this.initSorting()
        }

        this.initOutput()
    }

    createInitialModel() {
        const baseModel = {
            'results-per-page': this.config.resultsPerPage || 12,
            'sorting': this.config.defaultSorting || 'DEFAULT',
        }
        this.model = {
            ...baseModel,
            ...this.urlSearchParamsHandler.getParamsFromUrl()
        }
    }

    initOutput() {
        const maskOutput = this.module.querySelector('.product-overview__results')
        const containerOutput = maskOutput.querySelector('.container-center')
        this.output = new ProductOverviewOutput(containerOutput, maskOutput, this.config.translations, this.config.productBaseUrl)
    }

    initFilterBar() {
        const elementFilterBar = this.module.querySelector('.filter-bar')
        this.filterBar = new FilterBar(elementFilterBar)
    }

    initSidebarFilters() {
        const buttonShowFilters = this.module.querySelector('.filter-bar__button-open-filters')

        buttonShowFilters.addEventListener('click', () => {
            const fixedContentController = this.context.getService('fixed-content-controller')
            fixedContentController.openSidebarFilters()
        })

        const elementSidebarFilters = this.module.querySelector('.sidebar-filters')

        this.sidebarFilters = new SidebarFilters(
            elementSidebarFilters,
            this.filtersConfig,
            this.context,
            this.onInputModelChanged.bind(this),
            this.onPrefetchRequested.bind(this)
        )
    }

    onPrefetchRequested(filterModel) {
        const tempModel = { ...this.model, ...filterModel }
        this.prefetchFilters(tempModel, this.handlePrefetchFiltersSuccess.bind(this))
    }

    initPagination() {
        const html = this.module.querySelector('.pagination')
        const config = {
            name: 'actual-page',
            totalPages: 1,
        }
        this.pagination = new Pagination(html, config, this.onPaginationChanged.bind(this))
    }

    initSorting() {
        const containerSorting = this.module.querySelector('.filter-bar__wrapper-sort')
        const { sortConfig } = this.config
        const iconMobile =
            `<svg class="dropdown__arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.66 20.56" width="31.66px" height="20.56px">
                <path vector-effect="non-scaling-stroke" d="M30.83.56,19.51,17.39a4.56,4.56,0,0,1-3.64,2.17,4.56,4.56,0,0,1-3.65-2.17L.83.64" fill="none" stroke-miterlimit="10" stroke-width="2" />
             </svg>`
        this.dropdownSort = new Dropdown(sortConfig, this.onInputModelChanged.bind(this), iconMobile)
        containerSorting.appendChild(this.dropdownSort.html)
    }

    initCountResults() {
        const element = this.module.querySelector('.filter-bar__count-results-output')
        this.countResults = new CountResults(element)
    }

    get isConditionToggleEnabled() {
        if (!this.configGui.conditionToggle) return false

        const filterConfigConditionToggle = this.filtersConfig.find((filter) => {
            return filter && filter.name === CONDITION_TOGGLE_FILTER_NAME
        })

        return (
            !!filterConfigConditionToggle
            && !!filterConfigConditionToggle.items.find(({ name }) => name === CONDITION_TOGGLE_FILTER_ITEM_NEW)
        )
    }

    initConditionToggle() {
        this.conditionToggle = new ConditionToggle(
            this.module,
            this.filtersConfig,
            this.context,
            this.onConditionToggleChange.bind(this),
            this.onPrefetchRequested.bind(this)
        )
    }

    applyModelToInstances() {
        if (this.pagination) {
            this.pagination.setAndApplyModel(this.model)
        }

        if (this.dropdownSort) {
            this.dropdownSort.setAndApplyModel(this.model)
        }

        if (this.sidebarFilters) {
            this.sidebarFilters.setAndApplyModel(this.model)
        }
    }

    getNewPageModelFromIndex(indexOneBased) {
        return { 'actual-page': indexOneBased }
    }

    scrollToResultsStart() {
        const offsetHeightTopBar = -56
        const offsetForceFilterBarFixed = 1
        const moduleTop = this.module.querySelector('.filter-bar').getBoundingClientRect().top

        const top =
            window.pageYOffset
            + moduleTop
            + offsetHeightTopBar
            + offsetForceFilterBarFixed

        window.scrollTo({ top, behavior: 'smooth' })
    }

    onPaginationChanged(paginationModel) {
        this.model = { ...this.model, ...paginationModel }
        this.setUrlSearchParams()
        this.fetchResults()
    }

    onConditionToggleChange(filterModel) {
        const pristinePageModel = this.getNewPageModelFromIndex(1)
        this.model = { ...this.model, ...filterModel, ...pristinePageModel }

        this.sidebarFilters.setAndApplyModel(this.model)
        this.conditionToggle.setAndApplyModelAndConfig(this.model)

        this.prefetchFilters(this.model, this.updateSidebarAfterConditionToggleChange.bind(this))

        this.updatePaginationFromModel()
        this.setUrlSearchParams()
        this.fetchResults()
    }

    updateSidebarAfterConditionToggleChange({ filters, totalCount }) {
        this.sidebarFilters.onPrefetchSuccess(filters, totalCount)
        this.sidebarFilters.copyTempDataToData()
    }

    onInputModelChanged(filterModel, filterConfig = null) {
        const pristinePageModel = this.getNewPageModelFromIndex(1)
        this.model = { ...this.model, ...filterModel, ...pristinePageModel }

        if (this.isConditionToggleEnabled) {
            this.conditionToggle.setAndApplyModelAndConfig(this.model, filterConfig)
        }

        this.updatePaginationFromModel()
        this.setUrlSearchParams()
        this.fetchResults()
    }

    updatePaginationFromModel() {
        if (this.pagination) {
            this.pagination.setAndApplyModel(this.model)
        }
    }

    setUrlSearchParams() {
        this.urlSearchParamsHandler.setParams(this.model)
    }


    async fetchFiltersConfig() {
        let results = null

        try {
            results = await this.productOverviewApi.fetchFiltersConfig()
        } catch (error) {
            console.error('error')
        }

        if (results) {
            this.handleFiltersConfigFetched(results)
        }
    }

    async prefetchFilters(model, callback) {
        let results = null

        try {
            results = await this.productOverviewApi.fetchFiltersAndCount(model)
        } catch (error) {
            console.error('error')
        }

        if (results && callback) {
            callback(results)
        }
    }

    async fetchResults() {
        let results = null

        try {
            results = await this.productOverviewApi.fetchResults(this.model)
        } catch (error) {
            this.handleFetchError(error)
        }

        if (results) {
            this.handleResultsFetched(results)
        }
    }

    handleFiltersConfigFetched({ filters }) {
        this.filtersConfig = filters
        this.initSidebarFilters()
        this.sidebarFilters.setAndApplyModel(this.model)

        if (this.isConditionToggleEnabled) {
            this.initConditionToggle()
            this.conditionToggle.setAndApplyModelAndConfig(this.model, this.filtersConfig)
        }
    }

    handleInitialFilterPrefetchSuccess({ filters, totalCount }) {
        this.sidebarFilters.onPrefetchSuccess(filters, totalCount)

        if (this.isConditionToggleEnabled) {
            this.conditionToggle.setAndApplyModelAndConfig(null, filters)
        }
    }

    handlePrefetchFiltersSuccess({ filters, totalCount }) {
        this.sidebarFilters.onPrefetchSuccess(filters, totalCount)
    }

    handleResultsFetched({ results, totalCount }) {
        if (this.wasFirstFetch === false) {
            this.scrollToResultsStart()
        }

        this.results = results
        this.output.showResults(results)
        this.updateTotalCount(totalCount)
    }

    handleFetchError(error) {
        console.error('API call failed', error)
    }

    updateTotalCount(totalCount) {
        const totalPages = Math.ceil(Number(totalCount) / Number(this.model['results-per-page']))

        if (this.pagination) {
            this.pagination.setTotalPages(totalPages)
        }

        if (this.countResults) {
            this.countResults.setCount(totalCount)
        }
    }

    get wasFirstFetch() {
        return this.results === null
    }
}

