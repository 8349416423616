import BaseFilterParser from '@helpers/base-filter-parser'
import { colorNameHexCodeMap, colorOrderArray } from '../../constants/bike-color-constants'
import {CONDITION_TOGGLE_FILTER_ITEM_NEW} from './condition-toggle'

export default class ProductOverviewFilterParser extends BaseFilterParser {
    constructor(translations) {
        super()

        this.translations = translations

        this.rangeFiltersList = [
            'price',
            'bodySize',
        ]

        this.filterOptionsList = [
            'bikeTypes',
            'categories',
        ]

        this.filterOrderBySlug = {
            'condition_detail': 1,
            'series':           2,
            'gear':             3,
            'bikeTypes':        4,
            'price':            5,
            'color':            6,
            'bodySize':         7,
            'display':          8,
        }

        this.slugTypeMap = {
            'bikeTypes':        'select-options-multi',
            'series':           'select-options-multi',
            'gear':             'select-options-multi',
            'display':          'select-options-multi',
            'condition_detail': 'select-options-multi',
            'color':            'select-colors',
            'price':            'select-range',
            'bodySize':         'select-range-single',
        }

        this.filterExclusionMap = {
            'condition_detail': this.getExcludeConditionDetail.bind(this)
        }
    }

    getExcludeConditionDetail(parsedFilter) {
        const itemNames = parsedFilter.items.map(({ name }) => name)

        // single item -> either new or refurbished
        if (itemNames.length <= 1) {
            return true
        }

        // item "new" is missing, all items must be refurbished
        // if (!itemNames.includes(FILTER_ITEM_NEW)) {
        //     return true
        // }

        return false
    }

    getConfigColors(item) {
        const colorsConfig = super.getConfigColors(item)
        const sortedItems = this.getSortedColorItems(colorsConfig.items)
        const mappedItems = this.getMappedColorItems(sortedItems)

        colorsConfig.items = mappedItems

        return colorsConfig
    }

    getMappedColorItems(items) {
        return items.map(item => {
            const { name } = item
            item.color = colorNameHexCodeMap[name]
            item.label = this.translations.colorNames[name]
            return item
        })
    }

    getSortedColorItems(items) {
        const copyItems = [...items]

        return copyItems.sort((itemA, itemB) => {
            return colorOrderArray.indexOf(itemA.id) - colorOrderArray.indexOf(itemB.id)
        })
    }

    handleFilterConfigCreated(name, filterConfig, parent = null) {
        filterConfig.parent = parent
        super.handleFilterConfigCreated(name, filterConfig, parent)
    }

}
