import iconArrowRight from '@icon_templates/icon-arrow-right'

const templateCardService = `
    <div class="s-12 m-12 l-12">
        <a class="product-service<% if (!product.defaultImage) { %> product-service--no-image<% }; %>"
            href="<%= product.link %>" target="_self"
            data-module="product-list-card"
            data-product-slug="<%= product.slug %>">
            <div class="grid">

                <% if (product.defaultImage) { %>
                <div class="s-12 m-5 l-5">
                    <div class="product-service__container-figure">
                        <figure class="background" data-src="<%= product.defaultImage.url %>" data-alt="<%= product.defaultImage.alt || '' %>"></figure>
                    </div>
                    <% if (product.variants.length > 1) { %>
                    <div class="product-service__caption text-small"><%= product.dynamicTranslations.availableVariants %></div>
                    <% } %>
                </div>
                <% }; %>

                <div class="<% if (product.defaultImage) { %>s-12 m-7 l-7<% } else { %>s-12 m-12 l-12<% } %>">
                    <div class="product-service__wrapper-content">
                        <div class="product-service__wrapper-description">
                            <p class="product-service__info dateline addition"><%= product.category.translation && product.category.translation.name ? product.translation.category.name : product.category.name %></p>
                            <h2 class="product-service__headline"><%= product.translation && product.translation.name ? product.translation.name : product.name %></h2>
                            <%= product.translation && product.translation.description ? product.translation.description : product.description %>
                        </div>
                        <div class="product-service__wrapper-price">
                            <span class="product-service__price">
                                <% if (product.mixedPrices) { %>
                                <%= product.dynamicTranslations.priceFrom %>
                                <% } else { %>
                                <%= product.dynamicTranslations.price %>
                                <% } %>
                            </span>
                            <p class="product-service__tax text-small">
                                <% if (product.autoRenewal && product.subscriptionInterval === subscriptionInterval.YEARLY) { %>
                                    <%= staticTranslations.labelPriceAnnually %>
                                <% } else if (product.autoRenewal && product.subscriptionInterval === subscriptionInterval.MONTHLY) { %>
                                    <%= staticTranslations.labelPriceMonthly %>
                                <% } else { %>
                                    <%= staticTranslations.labelPrice %>
                                <% } %>
                            </p>
                            <div class="product-service__wrapper-button">
                                <div class="button-circle button-next button-label-left button-toggle-content spacing">
                                    <div class="circle">
                                        <span>${iconArrowRight}</span>
                                        <span>${iconArrowRight}</span>
                                    </div>
                                    <label class="button-label button-retailers"><%= staticTranslations.buttonSelect %></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
`

export default templateCardService
