import { getT3Service } from '@utils/t3-bridge'

const loadAllImages = (container) => {
    const figureHandler = getT3Service('figure-handler')
    const preloaderInstance = getT3Service('preloader').create({})

    const figures = figureHandler.getFigures(container)
    const allFigures = [...figures.preload, ...figures.lazyLoad]

    const promise = new Promise((resolve) => {
        preloaderInstance.loadImages(allFigures, (loadedFigures) => {
            figureHandler.showImages(loadedFigures)
            resolve()
        })
    })

    return promise
}

export { loadAllImages }
