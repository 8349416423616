import GraphQlBaseApi from './graph-ql-base-api'
import ProductOverviewFilterParser from '@modules/product-overview/product-overview-filter-parser'
import FilterQueryMapParser from '../../helpers/filter-query-map-parser'
import { getActualLanguage } from './../../helpers/language'
import { mapFilterLabelsFromTranslationsByName } from '../../helpers/map-filter-label'

export default class ProductOverviewApi extends GraphQlBaseApi {

    static productsCountSubQuery = `
                    products(first: $first, after: $after) {
                        totalCount
                    }`

    static productsSubQuery = `
                    products(first: $first, after: $after) {
                        totalCount
                        pageInfo{
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                        edges {
                            node {
                                name
                                gear
                                engine
                                productType {
                                    name
                                    isShippingRequired
                                }
                                category {
                                    name
                                    slug
                                    translation(languageCode: $languageCode) {
                                        name
                                    }
                                }
                                description
                                slug
                                translation(languageCode: $languageCode) {
                                    name
                                    description
                                    descriptionDetail
                                }
                                images {
                                    url
                                    alt
                                }
                                autoRenewal
                                subscriptionInterval
                                availability {
                                    priceRange {
                                        start {
                                            gross {
                                                amount
                                                localized
                                            }
                                        }
                                    }
                                }
                                variants {
                                    name
                                    stockQuantity
                                    price {
                                        amount
                                        localized
                                    }
                                    originalPrice {
                                        amount
                                        localized
                                    }
                                    metadata {
                                        ... on MetadataSingleItem {
                                            key
                                            value
                                        }
                                        ... on MetadataListItem {
                                            key
                                            values
                                        }
                                    }
                                    attributes {
                                        attribute {
                                            name
                                            slug
                                        }
                                        value {
                                            name
                                            value
                                            slug
                                            sortOrder
                                        }
                                    }
                                    images {
                                        url
                                        alt
                                    }
                                }
                            }
                        }
                    }`

    static filtersSubQuery = `
                    filters {
                        attributes {
                            name
                            label
                            values {
                                name
                                label
                                disabled
                            }
                        }
                        metadata {
                            name
                            label
                            values {
                                name
                                label
                                disabled
                            }
                        }
                        price {
                            baseMin
                            baseMax
                            filterMin
                            filterMax
                        }
                        bodySize {
                            baseMin
                            baseMax
                            filterMin
                            filterMax
                        }
                        bikeTypes {
                            name
                            label
                            disabled
                        }
                        countries {
                            name
                            label
                            disabled
                        }
                        categories {
                            name
                            label
                            disabled
                        }
                    }`

    constructor(config) {
        super()

        const { apiUrl, translations, baseFilter } = config

        this.endpoint = apiUrl
        this.translations = translations
        this.baseFilter = baseFilter
        this.filterParserInstance = new ProductOverviewFilterParser(this.translations)
        this.filterQueryMapParser = new FilterQueryMapParser()
        this.filterQueryMap = {}
    }

    parseResponse(response) {
        const { products } = response.data.data.productFilter

        return {
            results: this.parseProducts(products.edges),
            totalCount: products.totalCount,
        }
    }

    parseProducts(edges) {
        return edges.map(edge => edge.node)
    }

    parseFilters(response) {
        const { filters, products } = response.data.data.productFilter
        const totalCount = products ? products.totalCount : 0

        return {
            filters: this.filterParserInstance.parseFilters(filters),
            totalCount,
        }
    }

    excludeFilters(parsedFilters) {
        return this.filterParserInstance.excludeFilters(parsedFilters)
    }

    renameFilters(data) {
        const { filterNames } = this.translations
        const { filters } = data

        return {
            ...data,
            filters: mapFilterLabelsFromTranslationsByName(filters, filterNames),
        }
    }

    createFiltersGraphQlMap({ filters }) {
        this.filterQueryMap = this.filterQueryMapParser.getQueryMapFromFilters(filters)
    }

    getBaseVariables() {
        return {
            base: {
                ...this.baseFilter,
            },
            filter: {},
            languageCode: getActualLanguage(),
        }
    }

    checkIsValueDefined(value) {
        if (typeof value === 'string' || Array.isArray(value)) {
            return value.length > 0
        }

        if (typeof value === 'number') {
            return true
        }

        return false
    }

    getFilterVariablesFromModel(model) {
        const mappedFilters = {}

        Object.entries(model).forEach(([key, value]) => {
            const filterMap = this.filterQueryMap[key]
            const isValueValid = this.checkIsValueDefined(value)

            if (!filterMap || !isValueValid) {
                return
            }

            const { name, parent, isParentArray } = filterMap

            if (parent && isParentArray) {
                if (!mappedFilters[parent]) {
                    mappedFilters[parent] = []
                }

                mappedFilters[parent].push({ name, values: value })
                return
            }

            if (parent && !isParentArray) {
                if (!mappedFilters[parent]) {
                    mappedFilters[parent] = {}
                }

                mappedFilters[parent][name] = value
                return
            }

            mappedFilters[name] = value
        })

        return mappedFilters
    }

    getPaginationAfterBase64FromModel(model) {
        const offset = Number(model['results-per-page']) * (Number(model['actual-page']) - 1)
        const string = `arrayconnection:${offset - 1}`
        const base64String = btoa(string)
        return `"${base64String}"`
    }

    getFirstCountFromModel(model) {
        return parseInt(model['results-per-page'])
    }

    getVariablesFromModel(model) {
        return {
            first: this.getFirstCountFromModel(model),
            after: this.getPaginationAfterBase64FromModel(model),
            filter: this.getFilterVariablesFromModel(model),
        }
    }

    getSortingVariablesFromModel(model) {
        return {
            sorting: model.sorting,
        }
    }

    getQueryFilters() {
        return `
            query($base: ProductFilterInput, $filter: ProductFilterInput!) {
                productFilter(base: $base, filter: $filter) {
                    ${this.constructor.filtersSubQuery}
                }
            }`
    }

    getQueryFiltersAndCount() {
        return `
            query($base: ProductFilterInput, $filter: ProductFilterInput!, $first: Int, $after: String) {
                productFilter(base: $base, filter: $filter) {
                    ${this.constructor.filtersSubQuery}
                    ${this.constructor.productsCountSubQuery}
                }
            }`
    }

    getQueryResults() {
        return `
            query($base: ProductFilterInput, $filter: ProductFilterInput!, $first: Int, $after: String, $sorting: SortOption, $languageCode: String!) {
                productFilter(base: $base, filter: $filter, sorting: $sorting) {
                    ${this.constructor.productsSubQuery}
                }
            }`
    }

    getQueryResultsByCategory() {
        return `
            query($base: ProductFilterInput, $filter: ProductFilterInput!, $first: Int, $after: String, $sorting: SortOption, $languageCode: String!) {
                productFilter(base: $base, filter: $filter, sorting: $sorting, languageCode: $languageCode) {
                    ${this.constructor.productsSubQuery}
                }
            }`
    }

    async fetchFiltersConfig() {
        const query = this.getQueryFilters()
        const variables = { ...this.getBaseVariables() }

        const response = await this.callAPI(query, variables)
        const parsedFilters = this.parseFilters(response)
        const filtersWoExcluded = this.excludeFilters(parsedFilters)
        const renamedFilters = this.renameFilters(filtersWoExcluded)

        this.createFiltersGraphQlMap(renamedFilters)

        return renamedFilters
    }

    async fetchFiltersAndCount(model) {
        const query = this.getQueryFiltersAndCount()
        const variables = {
            ...this.getBaseVariables(),
            ...this.getVariablesFromModel(model),
        }

        const response = await this.callAPI(query, variables)
        return this.parseFilters(response)
    }

    async fetchResults(model) {
        const query = this.getQueryResultsByCategory()
        const variables = {
            ...this.getBaseVariables(),
            ...this.getVariablesFromModel(model),
            ...this.getSortingVariablesFromModel(model),
        }

        const response = await this.callAPI(query, variables)
        return this.parseResponse(response)
    }
}
